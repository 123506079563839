
export const LoginService = {

  signin(formUser, callback) {
    fetch("https://api.colombialivinghotels.com/login", {
    //fetch("/login", {
      method: 'POST',
      mode: 'cors',//'*cors',//'no-cors',//, *cors, same-origin',//'cors,'
      headers: {
          'Content-Type': 'application/json',
          //'Access-Control-Allow-Origin': '*'
          //'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
          //'Authorization': 'Bearer '+token
      },
      body: JSON.stringify(formUser)
    })
    .then(response => response.json())
    .catch(error => {
      console.error('Error:', error);
      alert("Error de conexión.");
    })
    .then(data => {
        //console.log("login :",data);
        console.log("login Message:",data.Message);
        callback(parseInt(data.Status) ,data.Message, data.User, data.Token);
    });
    
  },

  signout(callback) {
    //fakeAuthProvider.isAuthenticated = false;
    setTimeout(callback, 100);
  },
};